import React, {useState} from 'react'


import { handleError } from "../helpers/error.helper";
import { mainRequest } from '../services/main.service'

import '../style/main.css'




export default function Main() {
    const [isRequested, setIsRequested] = useState(false)
    const [userAccount, setUserAccount] = useState('')
    const [isUserWinner, setUIsUserWinner] = useState('')


    const handleRequestUserWinner = (userAccount) => {
        mainRequest.checkIfUserWinner({ userAccount: userAccount.toLowerCase() })
            .then(({data}) => {
                    setUIsUserWinner(data?.isUserWinner)
                    setIsRequested(true)
            })
            .catch((error) => handleError({ error }))
    }




    return (
        <main id="home" className="main">

            <div className="main-title">
                <div className="container">
                    <div className="title-items">
                        <div className="title-content">
                        <div className="title">
                            <h3>Please enter your <span>ETH wallet</span> in order to check if you are the winner of MetaShips airdrop</h3>
                            <input type="text" className={'input_field w-input'} onChange={(e) => setUserAccount(e.target.value)} />
                            <input type="button" className={'cta cta__sub w-button'} value="Check" onClick={() => handleRequestUserWinner(userAccount)}/>
                        </div>
                            {
                                isRequested ?
                                    <div className="result">
                                        { isUserWinner ?
                                            <p><span className={'winner'}>Congratulations</span>, you are in the winner list. Please check your wallet for prize.</p>
                                            : <p><span className={'lost'}>Unfortunately</span> you didn't win this time. Please stay with us for following campaigns.</p>
                                        }
                                    </div>
                                    :
                                    <></>
                            }

                        </div>  
                    </div>
                </div>
            </div>
        </main>
    )
}
